<template>
  <div class="Campaign">
    <div class="no-content" v-if="!campaign">
      <h2>Loading...</h2>
    </div>
    <div class="content" v-else>
      <div :style="{ 'background-color': campaign.pageData.bgBanner }">
        <v-img
          :src="campaign.pageData.bannerUrl"
          max-width="1200"
          class="mx-auto"
        ></v-img>
      </div>
      <v-container>
        <v-row id="campaign" class="text-center my-0">
          <v-col cols="12">
            <h2
              class="text-h4 primary--text text--darken-3 font-weight-light font-italic py-8"
            >
              CAMPANHA
            </h2>
            <div class="text-left px-4">
              <div class="subtitle-1">
                <span>Cliente</span>:
                <span class="font-weight-bold text-uppercase">{{
                  campaign.client
                }}</span>
              </div>
              <div class="subtitle-1">
                <span>Campanha</span>:
                <span class="font-weight-bold text-uppercase">{{
                  campaign.name
                }}</span>
              </div>
              <div class="subtitle-1">
                <span>Produto</span>:
                <span class="font-weight-bold text-uppercase">{{
                  campaign.product
                }}</span>
              </div>
              <div class="subtitle-1">
                <span>Peça</span>:
                <span class="font-weight-bold text-uppercase">
                  {{ campaign.type }} {{ campaign.format }}
                </span>
              </div>
              <div class="subtitle-1">
                <span>Veiculação</span>:
                <span class="font-weight-bold text-uppercase">{{
                  campaign.timeFrame
                }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row id="radios" class="text-center my-0">
          <v-col cols="12">
            <h2
              class="text-h4 primary--text text--darken-3 font-weight-light font-italic py-8"
            >
              RÁDIOS SELECIONADAS
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-simple-table
                v-if="campaign.id == 'programa-de-testagem-covid-19'"
                dense
                height="100%"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="primary darken-2 text-left white--text">
                        ID. SECOM
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        MUNICÍPIO
                      </th>
                      <th class="primary darken-2 text-left white--text">UF</th>
                      <th class="primary darken-2 text-left white--text">
                        RÁDIO
                      </th>
                      <th class="primary darken-2 text-left white--text">FX</th>
                      <th class="primary darken-2 text-left white--text">
                        PRX
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        PROGRAMA
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        APRESENTADOR
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        INÍCIO
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        FIM
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(radio, i) in campaign.radiosData"
                      :key="i"
                      :class="{ grey: isOdd(i), 'lighten-5': isOdd(i) }"
                    >
                      <td class="text-left">{{ radio.idSecom }}</td>
                      <td class="text-left">{{ radio.municipio }}</td>
                      <td class="text-left">{{ radio.uf }}</td>
                      <td class="text-left">{{ radio.name }}</td>
                      <td class="text-left">{{ radio.fx }}</td>
                      <td class="text-left">{{ radio.prx }}</td>
                      <td class="text-left">{{ radio.programa }}</td>
                      <td class="text-left">{{ radio.apresentador }}</td>
                      <td class="text-left">{{ radio.inicio }}</td>
                      <td class="text-left">{{ radio.fim }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table
                v-else
                fixed-header
                :dense="isMobile ? true : false"
                :height="
                  isMobile
                    ? campaign.radiosData.length > 10
                      ? 674
                      : '100%'
                    : campaign.radiosData.length > 10
                    ? 530
                    : '100%'
                "
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="primary darken-2 text-left white--text">
                        ID. SECOM
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        MUNICÍPIO
                      </th>
                      <th class="primary darken-2 text-left white--text">UF</th>
                      <th class="primary darken-2 text-left white--text">
                        RÁDIO
                      </th>
                      <th class="primary darken-2 text-left white--text">FX</th>
                      <th class="primary darken-2 text-left white--text">
                        PRX
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        PROGRAMA
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        APRESENTADOR
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        INÍCIO
                      </th>
                      <th class="primary darken-2 text-left white--text">
                        FIM
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(radio, i) in campaign.radiosData"
                      :key="i"
                      :class="{ grey: isOdd(i), 'lighten-5': isOdd(i) }"
                    >
                      <td class="text-left">{{ radio.idSecom }}</td>
                      <td class="text-left">{{ radio.municipio }}</td>
                      <td class="text-left">{{ radio.uf }}</td>
                      <td class="text-left">{{ radio.name }}</td>
                      <td class="text-left">{{ radio.fx }}</td>
                      <td class="text-left">{{ radio.prx }}</td>
                      <td class="text-left">{{ radio.programa }}</td>
                      <td class="text-left">{{ radio.apresentador }}</td>
                      <td class="text-left">{{ radio.inicio }}</td>
                      <td class="text-left">{{ radio.fim }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="campaign">
        <v-row id="content" class="text-center my-0">
          <v-col cols="12">
            <h2
              class="text-h4 primary--text text--darken-3 font-weight-light font-italic py-8"
            >
              CONTEÚDO DIVULGADO
            </h2>
            <div class="text-left px-4">
              <p class="font-weight-bold">
                <span>{{ campaign.client }}</span> <br />
                <span>
                  {{ campaign.name }}
                </span>
                <br />
                <span>{{ campaign.type }} {{ campaign.format }}</span> <br />
              </p>
              <div v-if="campaign.id == 'campanha-nacional-cuida-mais-brasil'">
                <p><b> PEÇA 01: CUIDA MAIS BRASIL</b></p>

                <div style="margin-left: 20px">
                  <p>
                    É, minha gente… Vocês sabem… A gente sempre sonha com o
                    melhor pros nossos filhos, né? Com eles crescendo fortes e
                    com saúde!
                  </p>
                  <p>
                    É por isso que, como pai, eu fico muito feliz em contar pra
                    todas as famílias que, agora, com o Cuida Mais Brasil, mais
                    crianças e mulheres vão poder ter acesso a atendimento de
                    qualidade com pediatras e ginecologistas-obstetras na
                    <i>Atenção Primaria à Saúde</i> em todo o país.
                  </p>
                  <p>
                    É mais cuidado desde o início da vida. Isso mesmo, na
                    Unidade Básica de Saúde aqui pertinho e na mais perto daí,
                    de onde você mora também. O Cuida Mais Brasil já começou a
                    levar mais atenção e cuidado pro Brasil inteiro.
                  </p>
                  <p>
                    É isso.... Cuida Mais Brasil. Cuidar para crescer e viver
                    melhor.
                  </p>
                </div>

                <p style="margin-top: 40px">
                  <b>PEÇA 02:CUIDA MAIS BRASIL (TESTEMUNHAL COMUNICADOR 2)</b>
                </p>

                <div style="margin-left: 20px">
                  <p>
                    É, minha gente… Vocês sabem… Quem é pai ou mãe sempre sonha
                    com o melhor pros filhos, né? Com eles crescendo fortes e
                    com saúde!
                  </p>
                  <p>
                    É por isso que eu fico muito feliz em contar pra todas as
                    famílias que, agora, com o Cuida Mais Brasil, mais crianças
                    e mulheres vão poder ter acesso a atendimento de qualidade
                    com pediatras e ginecologistas-obstetras na
                    <i>Atenção Primária à Saúde</i> em todo o país.
                  </p>
                  <p>
                    É mais cuidado desde o início da vida. Isso mesmo, na
                    Unidade Básica de Saúde aqui pertinho e na mais perto daí,
                    de onde você mora também. O Cuida Mais Brasil já começou a
                    levar mais atenção e cuidado pro Brasil inteiro.
                  </p>
                  <p>
                    É isso... Cuida Mais Brasil. Cuidar para crescer e viver
                    melhor.
                  </p>
                </div>
              </div>
              <div v-if="campaign.id == 'programa-de-testagem-covid-19'">
                <p>
                  BG: Mapa do Brasil apontando diversos municípios com postos de
                  testagem, logos e lettering "PLANO NACIONAL DE EXPANSÃO DA
                  TESTAGEM PARA O NOVO CORONAVÍRUS".
                </p>
                <p>
                  <span>LOC. APRESENTADOR(A)</span><br />
                  Já são milhões de brasileiros vacinados contra a Covid-19. E
                  para seguir com mais segurança, o Governo Federal intensifica
                  o Plano Nacional de Expansão da Testagem para o Novo
                  Coronavírus com testes rápidos. Em todo o Brasil, será
                  reforçada a testagem nas unidades de saúde e nos locais com
                  grande circulação de pessoas também, para reduzir a
                  transmissão do vírus no país.
                </p>
                <p>
                  O teste não dói, não incomoda, é gratuito e em apenas 15
                  minutos você já tem o resultado! Com a testagem em massa, o
                  Ministério da Saúde pode monitorar melhor o índice de contágio
                  e as novas variantes, além das pessoas que estão contaminadas,
                  mesmo as que não tiverem sintomas.
                </p>
                <p>
                  E quanto mais pessoas testadas, mais cuidamos uns dos outros e
                  podemos circular com mais segurança. Faça o teste nos postos
                  de saúde, UPAs ou postos de testagem da sua cidade, beleza?
                </p>
                <p>
                  Plano Nacional de Expansão da Testagem. Vamos testar. E seguir
                  com segurança.
                </p>
              </div>
              <div
                v-if="
                  campaign.id ==
                  'campanha-nacional-de-multivacinacao-para-criancas-e-adolescentes'
                "
              >
                <p class="font-weight-bold">
                  <span>PEÇA: B - TESTEMUNHAL DIA D 15/10/21</span> <br />
                  <span>MULTIVACINAÇÃO</span>
                  <br />
                  <span>Textos Merchan</span>
                </p>
                <p>DIA D</p>
                <p>
                  <span>Texto base 60"</span><br />
                  Atenção, papais, mamães e responsáveis. Chegou a hora da gente
                  falar sobre vacinação. Gente, neste sábado, dia 16 de outubro,
                  é dia de atualizar a caderneta de vacinação dos seus filhos. É
                  isso mesmo, dia de levar as crianças e adolescentes menores de
                  15 anos de idade ao posto de vacinação mais perto de você para
                  atualizar a caderneta com as vacinas que estão faltando. São
                  18 vacinas disponíveis para proteger seus filhos e sua família
                  de doenças como poliomielite, sarampo, rubéola, caxumba, entre
                  muitas outras.
                </p>
                <p>
                  No site gov.br/saude você encontra todas as vacinas
                  disponíveis para a proteção das crianças e adolescentes, dê
                  uma olhada! Não se esqueça! É neste sábado, dia 16 de outubro,
                  e tem que levar a caderneta de vacinação, viu?
                </p>
                <p>
                  Quando protegemos nossas crianças, protegemos o país inteiro.
                  Entre nesse movimento. É o...Movimento Vacina Brasil! É mais
                  proteção para todos!
                </p>
                <div class="text-left px-4 mt-12">
                  <p class="font-weight-bold">
                    <span>PEÇA: C - TESTEMUNHAL PERIODO 22 A 26/10/21</span>
                    <br />
                    <span>MULTIVACINAÇÃO</span>
                    <br />
                    <span>Textos Merchan</span>
                  </p>
                  <p>PERÍODO</p>
                  <p>
                    <span>Texto base 60"</span><br />
                    Pessoal, vamos falar da Campanha de Multivacinação? Esse é
                    um recado para todos os pais, mães e responsáveis: tá na
                    hora de atualizar a caderneta de vacinação para crianças e
                    adolescentes menores de 15 anos de idade. É isso aí, de 1º a
                    29 de outubro leve seus filhos ao posto de vacinação mais
                    perto de você para atualizar a caderneta com as vacinas que
                    estão faltando. São 18 vacinas disponíveis para proteger
                    seus filhos e sua família de doenças como poliomielite,
                    sarampo, rubéola, caxumba, entre muitas outras. São doenças
                    muito perigosas que podem prejudicar o futuro dos seus
                    pequenos!
                  </p>
                  <p>
                    Saiba mais no site gov.br/saude onde você encontra todas as
                    vacinas disponíveis nessa campanha, dê uma olhada! Vamos
                    vacinar e proteger nossas crianças e adolescentes? Não se
                    esqueça de levar a caderneta, viu? Faça parte desse
                    movimento! É o...Movimento Vacina Brasil. É mais proteção
                    para todos!
                  </p>
                </div>
                <div class="text-left px-4 mt-12">
                  <p class="font-weight-bold">
                    <span>PEÇA: D - TESTEMUNHAL ULTIMOS 27 A 29/10/21 </span>
                    <br />
                    <span>MULTIVACINAÇÃO</span>
                    <br />
                    <span>Textos Merchan</span>
                  </p>
                  <p>ÚLTIMOS DIAS</p>
                  <p>
                    <span>Texto base 60"</span><br />
                    Agora é o momento de falar de saúde e da proteção das
                    crianças e adolescentes. Pais, mães e responsáveis, o
                    período de atualizar a caderneta de vacinação dos seus
                    filhos está chegando ao fim! Até 29 de outubro você pode
                    levar as crianças e adolescentes menores de 15 anos de idade
                    ao posto de vacinação mais perto de você para atualizar a
                    caderneta com as vacinas que estão faltando. São 18 vacinas
                    disponíveis para proteger seus filhos e sua família de
                    doenças como poliomielite, sarampo, rubéola, caxumba, entre
                    muitas outras. São doenças muito perigosas que podem
                    prejudicar o futuro dos seus pequenos!
                  </p>
                  <p>
                    Saiba mais no site gov.br/saude onde você encontra todas as
                    vacinas disponíveis nessa campanha, dê uma olhada! Vamos
                    vacinar e proteger nossas crianças e adolescentes? Não se
                    esqueça de levar a caderneta, viu? Faça parte desse
                    movimento! É o...Movimento Vacina Brasil. É mais proteção
                    para todos!
                  </p>
                  <p>
                    No site gov.br/saude você encontra todas as vacinas
                    disponíveis nessa campanha, dê uma olhada! Vamos vacinar e
                    proteger nossas crianças e adolescentes? São os últimos
                    dias, hein? Não se esqueça de levar a caderneta, combinado?
                  </p>
                  <p>Movimento Vacina Brasil. É mais proteção para todos!</p>
                </div>
              </div>

              <div
                v-if="
                  campaign.id ==
                  'campanha-nacional-de-prevencao-a-variola-dos-macacos'
                "
              >
                <strong>LOCUTOR:</strong>
                <p>
                  Vocês já devem ter visto notícias nos jornais e pelas redes
                  sociais sobre a Varíola dos Macacos, não é?
                </p>
                <p>
                  O mais importante agora é ter a informação segura e confiável.
                  No site <strong>www.gov.br/varioladosmacacos</strong>, o
                  Ministério da Saúde organizou tudo o que você precisa saber
                  para se cuidar.
                </p>
                <p>Algumas informações importantes eu já vou adiantar!</p>
                <p>
                  A transmissão da Varíola dos Macacos ocorre pelo contato
                  físico de uma pessoa infectada para outra pessoa, por
                  secreções e fluidos corporais, ou por meio de objetos
                  contaminados.
                </p>
                <p>
                  Os principais sinais e sintomas são lesões na pele, febre,
                  inchaço dos gânglios, dor de cabeça, dores no corpo, calafrios
                  e exaustão.
                </p>
                <p>
                  Em caso de sinais e sintomas, procure imediatamente uma
                  Unidade de Saúde.
                </p>
                <p>
                  Para saber mais, acesse
                  <strong> www.gov.br/varioladosmacacos. </strong>
                </p>
                <p>Fique bem com a informação certa.</p>
                <p>Ministério da Saúde</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row id="censure" class="text-center">
          <v-col cols="12">
            <h2
              class="text-h4 primary--text text--darken-3 font-weight-light font-italic py-8"
            >
              CENSURA
            </h2>
          </v-col>
        </v-row>
        <v-row class="pa-4">
          <v-col cols="12">
            <v-card v-if="campaign">
              <v-card-title>
                {{ campaign.name }}
              </v-card-title>
              <v-card-subtitle>{{ campaign.client }}</v-card-subtitle>
              <v-card-text class="">
                <v-row>
                  <v-col cols="12">
                    <strong>Qtde.:</strong>
                    <span>
                      {{
                        campaign.censuresData ? campaign.censuresData.length : 0
                      }}
                    </span>
                    <span class="mx-2"> | </span>
                    <strong>P.i.:</strong>
                    <span>
                      {{
                        campaign.piCamp
                          ? campaign.piCamp
                          : campaign.censuresData
                          ? campaign.censuresData[0].pi
                          : ''
                      }}
                    </span>
                    <span class="mx-2"> | </span>
                    <strong>Período:</strong>
                    <span>
                      {{
                        campaign.timeFrame
                          ? campaign.timeFrame
                          : campaign.censuresData
                          ? campaign.censuresData[0].periodo
                          : ''
                      }}
                    </span>
                    <span class="mx-2"> | </span>
                    <strong>Tipo/Duração:</strong>
                    <span>
                      {{
                        campaign.type
                          ? campaign.type
                          : campaign.censuresData
                          ? campaign.censuresData[0].tipo
                          : ''
                      }}/{{
                        campaign.format
                          ? campaign.format
                          : campaign.censuresData
                          ? campaign.censuresData[0].formato
                          : ''
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="campaign.censuresData"
                  :search="search"
                  sort-by="idSecom"
                  show-expand
                  :expanded.sync="expanded"
                  :footer-props="{
                    'items-per-page-all-text': 'Todas',
                    'items-per-page-text': 'Censuras por página:',
                    'items-per-page-options': [15, 50, 100, -1],
                  }"
                  class="elevation-1"
                  item-key="idApp"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>CENSURAS</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td
                      :colspan="headers.length"
                      class="pb-8 pt-6 grey--text text--darken-1"
                    >
                      <strong>Programa:</strong> {{ item.programa }}
                      <span class="mx-2"> | </span>
                      <strong>Apresentador:</strong> {{ item.apresentador }}
                      <span class="mx-2"> | </span>
                      <strong>Hor. Início:</strong> {{ item.inicio }}
                      <span class="mx-2"> | </span> <strong>Hor. Fim:</strong>
                      {{ item.fim }}
                    </td>
                  </template>
                  <template v-slot:item.audio="{ item }">
                    <div v-if="item.audioData">
                      <v-icon
                        small
                        color="primary"
                        @click="openAudioPlayer(item)"
                      >
                        mdi-play-circle
                      </v-icon>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <p class="mt-4">
                      Nenhuma censura encontrada! Os registros cadastrados
                      aparecerão aqui.
                    </p>
                  </template>
                  <template v-slot:no-results>
                    <p class="mt-4">
                      Nenhuma resultado encontrado para busca: {{ search }}
                    </p>
                  </template>
                </v-data-table>
              </v-card-text>

              <v-card-actions class="pb-4 pr-4">
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="expandAll()"
                  >{{ expanded.length ? 'Fechar' : 'Expandir' }} Todos</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="grey lighten-2 mt-12" id="footer">
        <v-container>
          <v-row id="censure" class="text-center my-0">
            <v-col cols="12">
              <!-- <p>footer</p> -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <!-- bottom audio player Dialog -->
    <v-bottom-sheet
      inset
      v-model="playerDialog"
      @click:outside="closeAudioPlayer()"
    >
      <v-card tile>
        <v-progress-linear
          :value="100"
          class="my-0"
          height="3"
        ></v-progress-linear>

        <v-container class="pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" sm="4">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ audioPlayerData.veiculo }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ audioPlayerData.apresentador }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption">
                      {{ audioPlayerData.programa }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" class="mt-3 d-flex justify-center">
              <v-btn
                class="mr-2 mt-2 elevation-0 fixed-bottom"
                dark
                small
                color="primary"
                @click="playSprite()"
              >
                <v-icon dark small> mdi-play </v-icon>
                Tocar o trecho da Censura
              </v-btn>
              <audio
                v-if="audioPlayerData"
                :src="audioPlayerData.src"
                ref="audioPlayer"
                controls
                style="width: 100%"
              ></audio>
            </v-col>
            <v-col cols="12" sm="2" class="mb-8 mb-sm-2">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ audioPlayerData.veiculacao }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ audioPlayerData.time }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption">
                      {{ audioPlayerData.numInsercao }} |
                      {{ audioPlayerData.numTexto }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  import {
    db,
    onSnapshot,
    collection,
    query,
    where,
  } from '@/firebase/firebase';

  export default {
    name: 'Campaign',

    components: {
      //
    },

    mounted() {
      if (this.$route.params.id) {
        // this.subscribeListerner();
        this.$store.dispatch(
          'loadCampaignWithOnlyPublishedCensures',
          this.$route.params.id
        );
      }
    },

    destroyed() {
      this.unsubscribeListener();
      if (this.campaign)
        this.$store.dispatch('loadCampaignWithCensuresData', this.campaign.id);
    },

    data() {
      return {
        selectedDate: null,
        headers: [
          {
            text: 'Data Veic.',
            sortable: true,
            value: 'veiculacao',
          },
          {
            text: 'Horário',
            sortable: true,
            value: 'audioData.time',
          },
          {
            text: 'Agência',
            sortable: true,
            value: 'agencia',
          },
          {
            text: 'iD Secom',
            sortable: true,
            value: 'idSecom',
          },
          {
            text: 'Pi Veículo',
            sortable: true,
            value: 'piVeiculo',
          },
          {
            text: 'Estado',
            sortable: true,
            value: 'uf',
          },
          {
            text: 'Município',
            sortable: true,
            value: 'municipio',
          },
          {
            text: 'Veículo',
            sortable: true,
            value: 'veiculo',
          },
          {
            text: 'Apresentador',
            sortable: true,
            value: 'apresentador',
          },
          { text: 'Áudio', value: 'audio', sortable: false, align: 'center' },
        ],
        search: '',
        expanded: [],
        censureList: null,
        internalLoading: null,
        unsubscribeListener: null,
        playerDialog: false,
        audioPlayerData: {},
        selectedAudio: null,
      };
    },

    computed: {
      campaign() {
        return this.$store.getters.getCampaign;
      },
      feedback() {
        return this.$store.getters.getFeedback;
      },
      user() {
        return this.$store.getters.getUser;
      },
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      },
      loading() {
        return this.$store.getters.getLoading;
      },
    },

    watch: {
      campaign(v) {
        if (v) {
          this.subscribeListerner();
        }
      },
    },

    methods: {
      expandAll() {
        // console.log(this.expanded.length);
        if (!this.expanded.length) {
          this.expanded = this.campaign.censuresData;
        } else {
          this.expanded = [];
        }
      },
      subscribeListerner() {
        console.log('Subscribe Listener');
        this.internalLoading = true;
        const q = query(
          collection(db, 'campaigns', this.$route.params.id, 'censuresData'),
          where('status', '>=', 2)
        );
        this.unsubscribeListener = onSnapshot(q, (querySnapshot) => {
          const newCampaign = this.campaign;
          if (newCampaign) newCampaign.censuresData = [];
          querySnapshot.forEach((doc) => {
            const newCensure = {
              id: doc.id,
              ...doc.data(),
            };
            newCensure.veiculacao = newCensure.veiculacao
              .toDate()
              .toLocaleDateString('pt-BR');
            newCampaign.censuresData.push(newCensure);
          });
          this.$store.dispatch('UpdateVuexCampaign', newCampaign);
        });
      },
      isOdd(num) {
        return num % 2;
      },
      playAudio2(id, src, start) {
        const player = document.getElementById(id);
        player.src = src;
        player.type = 'audio/mpeg';
        if (player.paused == true) {
          if (start) player.currentTime = start;
          player.play();
        } else {
          player.pause();
        }
      },
      openAudioPlayer(item) {
        console.log('Open Player Dialog');
        this.selectedAudio = item.audioData;
        this.playerDialog = true;
        this.audioPlayerData.src = item.audioData.audioUrl;
        this.audioPlayerData.veiculo = item.veiculo;
        this.audioPlayerData.apresentador = item.apresentador;
        this.audioPlayerData.programa = item.programa;
        this.audioPlayerData.veiculacao = item.veiculacao;
        this.audioPlayerData.time = item.audioData.time;
        this.audioPlayerData.numTexto = item.numTexto;
        this.audioPlayerData.numInsercao = item.numInsercao;
        console.log(this.player);
      },
      closeAudioPlayer() {
        this.audioPlayerData = {};
        // this.audioPlayerData.src = null;
        this.$refs.audioPlayer.src = null;
        this.selectedAudio = null;
        console.log('closing...');
      },
      playSprite() {
        const player = this.$refs.audioPlayer;
        const initM = this.selectedAudio.cut.inicioMinutos;
        const initS = this.selectedAudio.cut.inicioSegundos;
        const finalM = this.selectedAudio.cut.fimMinutos;
        const finalS = this.selectedAudio.cut.fimSegundos;
        const initialTime = parseInt(initM) * 60 + parseInt(initS);
        let finalTime = parseInt(finalM) * 60 + parseInt(finalS);
        if (finalTime < 1) finalTime = player.duration;
        if (initialTime > finalTime)
          this.audioFeedback =
            'Tempo inicial do corte está maior que o tempo final...';
        if (player && initialTime <= finalTime) {
          player.currentTime = initialTime;
          this.spriteStatus = true;
          player.play();
          const playInterval = setInterval(() => {
            if (player.currentTime >= finalTime) {
              player.pause();
              clearInterval(playInterval);
              this.spriteStatus = false;
            }
          }, 1000);
        }
      },
      secondsToMinutes(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
      },
    },
  };
</script>

<style scoped>
  .Campaign {
    padding: 0;
  }
  #footer {
    height: 400px;
  }
  .push-top-right {
    position: absolute;
    right: 21px;
    top: 21px;
  }
  .fixed-bottom {
    position: absolute;
    bottom: -3px;
  }
</style>
